import type { Tag, Meta } from '~/types'
import { TagStatus, TagType } from '~/enums'

interface State {
  items: Tag[]
  slugs: string[]
  cached: Map<string, Tag[]>
  cachedSlugs: Map<string, Tag>
  pagination: Meta
  pending: boolean
  isLoaded: boolean
}

export const useTagStore = defineStore('tag', {
  state: (): State => {
    return <State>{
      items: [],
      slugs: [],
      cached: new Map<string, Tag[]>(),
      cachedSlugs: new Map<string, Tag>(),
      pagination: {} as Meta,
      pending: false,
      isLoaded: false
    }
  },
  actions: {
    async init() {
      const query = {
        'filter[status]': TagStatus.Enabled,
        sort: 'position',
        perPage: 500
      } as any

      for (const [key, type] of [
        TagType.VillageType,
        TagType.VillageClass,
        TagType.VillageComplete,
        TagType.PurposeLand,
        TagType.Highway,
        TagType.Communications,
        TagType.Heating,
        TagType.Safety,
        TagType.Infrastructure,
        TagType.VillageNear,
        TagType.Road,
        TagType.Transport,
        TagType.District,
        TagType.HouseMaterial,
        TagType.InteriorDecoration,
        TagType.Furniture
      ].entries()) {
        query[`filter[type][${key}]`] = type;
      }

      this.pending = true

      const result= await useAPIFetch<{ data: Tag[] }>('/v1/tags/search', {
        query
      })

      this.pending = false

      this.items = result.data

      if (this.items) {
        for (const item of result.data) {
          this.slugs.push(item.slug)
          this.cachedSlugs.set(item.slug, item)
        }
      }

      this.isLoaded = true
    },
    search(params?: any){
      return useAPIFetch<{ data: Tag[] }>('/v1/tags/search', params)
    },
    findByType(type: TagType) {
      if (this.cached.has(type)) {
        return this.cached.get(type) as []
      }

      const items = this.items.filter((item: Tag) => item.type === type) ?? []
      this.cached.set(type, items)

      return items
    },
    findByValue(value: string): Tag | undefined {
      return this.items.find((item: Tag) => item.value === value)
    },
    findByValues(values: string[]): Tag[] {
      return this.items.filter((item: Tag) => {
        if (!item.value) {
          return false
        }
        return values.includes(item.value)
      })
    }
  }
})
